.all-person-boxes {
    display: flex;
    flex-direction: column;
    margin: 20px;
}
.person-boxes-row {
    display: flex;
    justify-content: space-evenly;
    margin: 50px;
}
.person-box {
    max-width: max-content;
    max-height: max-content;
    position: relative;
    box-shadow: rgba(140, 140, 140, 0.75) 0px 0px 10px -2px;
}
.person-img {
    max-width: 250px;
    height: 100%;
    max-height: 250px;
    vertical-align: middle;
}
.person-image-wrapper {
    overflow: hidden;
}
.person-img-info {
    position: absolute;
    bottom: 0%;
    color: white;
    text-align: center;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(43, 43, 43) 90%);
}
.person-img-name {
    font-weight: bold;
}