.support {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  .support-box {
      text-align: center;
      margin: 15% auto; 
      font-size: 16px;
      font-weight: 400;
      background: #f6f6f9;  
      padding: 20px;
      max-width: 400px;
      display: flex;
      flex-direction: column; 
      align-items: center; 
      justify-content: space-around; 
      box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px 0px;
      border-radius: 10px;
  }
  .contact-info-wrapper {
      display: flex;
  }
  .contact-info-wrapper2 {
      margin-left: 10px;
      color: blue;
      text-decoration: underline;
  }
  .mailing-address-wrapper {
      display: flex;
  }
  .mailing-address {
      margin-left: 10px;
  }