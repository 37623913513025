@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ultra&display=swap');


body {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
#mobile-cover {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: max-content;
  background-color: white;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  text-align: center;
  padding: 15px 30px;
  box-shadow: black 0px 0px 10px -2px;
}
#mobile-cover a {
  color: #fc8eac;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar #title {
  color: black; 
  font-family: 'Ultra', serif;
  font-size: 30px;
  font-weight: 500;
  outline: black dashed 5px;
  background-color: rgb(243, 241, 241); 
  box-shadow: black; 
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  text-decoration: none;
}
.links a:hover {
  color: white;
  background-color: #fc8eac;
  border-radius: 8px;
}
.content {
  margin: 0px auto;
  padding: 20px;
}
.dashboard a {
  padding: 6px;
  text-decoration: none;
  background-color: #FFE013;
  color: #231B14;
  border-radius: 8px;
  margin-top: 10px;
}
.side-nav {
  height: 100%; 
  width: 12%;
  position: fixed; 
  z-index: 1; 
  top: 0; 
  left: 0; 
  background-color: #f6f6f9;; 
  transition: .5s ease; 
  overflow-x: hidden; 
  padding-top: 20px; 
  display: flex; 
  flex-direction: column; 
  align-items: center;
}
.side-nav-links{
  color: black;
  text-decoration: none;
  padding: 45px 12px;
}
.admitted-logo-offwhite {
  width: 100%
}
.admitted-logo-white {
  height: 60px;
}
.border-div {
  border: 0.5px solid #DBD9D2;
  width: 50%;
}
.side-nav-icon {
  font-size: 30px;
}
.side-nav-links:hover {
  transform: matrix(1.05, 0, 0, 1.05, 0, 0);
	transition: transform 0.3s ease 0s;
}
.content2 {
  text-align: center;
  margin-left: 50px;
}
.recent-activity {
  margin-top: 30px;
}
.dashboard {
  text-align: center;
}
.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home h1 {
	font-family: "Means Web", Georgia, Times, "Times New Roman", serif;
	font-size: 48px;
	font-weight: 400;
	letter-spacing: -1px;
	line-height: 48px;
	text-align: center;
	color: rgb(36, 28, 21);
	height: 48px;
	width: 853.336px;

}
#main-blurb {
  margin: 20px 0; 
	font-family: "Nunito Sans", Helvetica, Arial, Verdana, sans-serif;
	font-size: 24px;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	letter-spacing: -0.32px;
	line-height: 32px;
	text-align: center;
  color: rgb(36, 28, 21);
	color: rgb(36, 28, 21);
	height: 64px;
	width: 853.336px;
}
.home .mainbutton, .navbar .mainbutton {
	font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: normal;
	line-height: 19.5px;
	text-decoration: none solid rgb(36, 28, 21);
	text-align: center;
  position: relative;
  top: 0px; 
	background-color: #fc8eac;
	color: rgb(36, 28, 21);
	height: 43.5px;
	width: 138.305px;
	padding: 12px 24px;
	cursor: pointer;
	box-sizing: border-box;
	box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 0.25008px 0px 0px;
  border-radius: 26px; 
}
.home .mainbutton:hover, .navbar .mainbutton:hover {
	transition: top 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
  box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
  top: -3.05008px;
}

.navbar .logbutton{
	/* Font & Text */
	font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 500;
	line-height: 19.5px;
	text-align: center;
  position: relative;
  top: 0px; 

	/* Color & Background */
	background-color: rgba(36, 28, 21, 0);
	color: rgb(36, 28, 21);

	/* Box */
	height: 43.5px;
	width: 91px;
	margin: 0px;
	padding: 12px 24px;
	min-height: 0px;
	min-width: 91px;
	cursor: pointer;
	

	/* Effects */
	transition: transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7) 0s, box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7) 0s, -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7) 0s, -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7) 0s;
	box-sizing: border-box;
	box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
	border-radius: 26px; 
}

.navbar .logbutton:hover {
  transition: top 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
  box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
  top: -3.05008px;
}

.admitted-next-btn-wrap {
  margin-top: 50px;
  text-align: center;
}
.admitted-next-btn {
  font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  position: relative;
  top: 0px; 
  background-color: #fc8eac;
  color: rgb(36, 28, 21);
  height: 43.5px;
  width: max-content;
  padding: 12px 24px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 26px; 
  border: 1px solid black;
  text-decoration: none;
}
.admitted-next-btn:hover {
  transition: top 0.1s ease-in-out, transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
  box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
  top: -5px;
}

.test-class {
  display: flex;
  padding: 0.5rem;
  gap: 1rem;
}
.nav-dropdown-link {
  background: none;
  border: none;
  text-decoration: none;
  color: #777;
  font-family: inherit;
  font-size: inherit;
  padding: 6px;
  cursor: pointer;
} 
.nav-dropdown {
  position: relative;
}
.nav-dropdown-menu {
  position: absolute;
  left: 0;
  top: calc(100% + .05rem);
  background-color: white;
  padding: 1rem;
  margin-right: 15px;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.3);
  z-index: 2;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  display: flex;
  flex-direction: column;
  width:max-content;
  pointer-events: none;
}
.nav-dropdown.active > .nav-dropdown-link,
.nav-dropdown-link:hover {
  color: black;
}
.nav-dropdown.active > .nav-dropdown-link + .nav-dropdown-menu {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
.nav-dropdown-menu-link {
  color: black;
}
.nav-dropdown-menu-link1 {
  color: black;
  margin-top: 15px;
}
.nav-dropdown-menu-link:hover, .nav-dropdown-menu-link1:hover {
  color: #fc8eac;
}