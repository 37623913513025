
#test-cover {
  width: 100%;
  height: 500%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fc8eac;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
}
#test-cover p {
  text-align: center;
}
.feature-cards {
  max-width: 360px;
  max-height: 526px;
  margin-top: 100px;
}
.zoom-imgs {
  width: 100%;
  transition: transform 0.3s;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;
}
.zoom-imgs:hover{
  transform: scale(1.05);
  overflow: hidden;
}
.feature-card-section {
  display: flex;
}
.image-wrapper {
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  aspect-ratio: 360 / 525.062;
}
.top-left {
  position: absolute;
  top: 5%;
  left: 22%;
  text-decoration: none;
  color: white;
  background-color: #fc8eac;
  text-shadow: 0 0 3px black;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 8px;
  width: max-content;
  max-height: 63px;
  box-shadow: rgba(140, 140, 140, 0.75) 0px 0px 10px -2px;
}
.card-info {
  margin: 0;
  height: 190px;
  width: 100%;
  position:relative;
}
#card-info-0 {
  background-color: #FDB5C9;
}
#card-info-0-button {
  background-color: #FDB5C9;
  top: 65%;
  left: 32%;
}

#card-info-0-button:hover {
transition: top 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
top: 63.5%; 
}
#card-info-1-button:hover {
  transition: top 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
  box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
  top: 63.5%; 
}
#card-info-2-button:hover {
  transition: top 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
  box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
  top: 63.5%; 
}
#card-info-3-button:hover {
  transition: top 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
  box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
  top: 63.5%; 
}

#card-info-1 {
  background-color: #FECEDB;
}
#card-info-1-button {
  background-color: #FECEDB;
  top: 65%;
  left: 32%;
}
#card-info-2 {
  background-color: #FEE6ED;
}
#card-info-2-button {
  background-color: #FEE6ED;
  top: 65%;
  left: 32%;
}
#card-info-3 {
  background-color: #FFF7F9;
}
#card-info-3-button {
  background-color: #FFF7F9;
  top: 65%;
  left: 32%;
}
.top-left:hover {
  text-decoration: underline;
}
.card-info-blurb {
  position: absolute;
  top: 10%;
  left: 10%;
  max-width: 300px;
}
.free-trial-banner {
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
}
.free-trial-banner p {
  margin: 0;
  padding: 5px;
}
.free-trial-banner a {
  color: white;
}
.free-trial-banner a:hover {
  color: #fc8eac;
}
.card-info .mainbutton {
  width: 130px;
}
#main-blurb {
  height: fit-content;
}
.meet-our-reviewers {
  margin-top: 225px;
  text-align: center;
  width: 100%;
}
.meet-our-reviewers h3 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 48px;
  text-align: center;
}
.profile-section {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 8px 0;
}
.profile-margin {
  margin: 0px 50px;
  height: 350px;
  width: 300px;
}
.home-pay-options {
  height: max-content;
  margin-top: 175px;
}

