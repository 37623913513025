.reviewerdashboard {  
    background-color: #252A41;
}

.reviewerdashboard .userdashboard {
    position: fixed; 
    margin: 0;
    top: 0; 
    background-color: #252A41;
    width: 100%; 
    height: 100%; 
    display:flex; 
    flex-direction: column;  
    justify-content: center; 
}

.userdashboard {
    position: fixed; 
    margin: 0;
    top: 0; 
    background-color: #252A41;
    width: 100%; 
    height: 100%; 
    display:flex; 
    flex-direction: column;  
    justify-content: center; 
}

.userdashboard #left-side {
    height: 450px;
    position: fixed; 
    left: 125px; 
    
}

.userdashboard #left-side h1{
    font-family: 'Inter';
    color: white; 
}

.userdashboard #left-side h3{
    margin-top: 70px; 
    font-family: 'Inter';
    color: white; 
}

.userdashboard #left-side .left-side-p{
    color: #686A7B; 
}

#right-side {
    position: fixed; 
    right: 10px; 
    height: 97%; 
    width: 50%;
    background-color: white; 
    border-radius: 25px; 
}


#right-side .current_projects{   
    height: 225px;
    width: 100%; 
    display: flex; 
    align-items: flex-start; 
    overflow-x: scroll;
}

#right-side .current_projects #upload {
    background-color: white; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    height: 172px;
	width: 140px;
    min-width: 140px;
    margin-left: 30px; 
    margin-top: 20px; 
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer; 
}


#right-side .current_projects #upload img{
    width: 50px; 
    height: 50px; 
    margin-top: 30px; 
    margin-right: 5px; 
}

#right-side .current_projects #upload h2 {
    display: block; 
    font-family: 'Lato', sans-serif;
}

.right-side-header {
    font-family: 'Lato', sans-serif;
}


#right-side hr {
    color: lightgray;
    width: 95%;
}