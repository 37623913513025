.cost-header {
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
}
.cost-header-underline {
    border-bottom: 8px solid #fc8eac;
}
.cost-main-text {
    max-width: 50%;
    margin: 10px auto;
    text-align: center;
}
.calc-header {
    text-align: center;
    margin-bottom: 40px;
    padding-bottom: 10px;
    border-bottom: 8px solid #fc8eac;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
}
.calc-questions-all {
    max-width: 90%;
    padding: 20px;
    margin: 100px auto 30px auto;
    border-radius: 20px;
    background-color: #f6f6f9;
    box-shadow: rgba(140, 140, 140, 0.75) 0px 0px 10px -2px;
}
.calc-popout {
    position: relative;
}
.calc-question {
    background: none;
    border: none;
    border-bottom: 1px dashed black;
    padding: 10px 0 0 0;
    cursor: pointer;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
}
.calc-popout-info {
    position: absolute;
    left: calc(100% + .50rem);
    top: -50%;
    max-width: 250px;
    min-width: 250px;
    background-color: white;
    padding: 1rem;
    border-radius: .25rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.3);
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transform: translateX(-10px);
    transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
}
.calc-popout-info-header {
    text-align: center;
}
.calc-popout-info-text {
    height: 100px;
    overflow-y: scroll;
    font-size: 14px;
    word-spacing: auto;
    padding: 0 8px;
}
.calc-popout-info-text a {
    text-decoration-skip-ink: none;
}
.calc-popout-info-text span {
    font-weight: bold;
}
  
  /* Customize the scrollbar style */
  .calc-popout-info-text::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
  }
  
  .calc-popout-info-text::-webkit-scrollbar-thumb {
    background-color: #888; /* Set the color of the thumb (the draggable part of the scrollbar) */
    border-radius: 4px; /* Round the corners of the thumb */
  }
  
  .calc-popout-info-text::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Set the color of the thumb on hover */
  }
  
  .calc-popout-info-text::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the color of the track (the non-draggable part of the scrollbar) */
    border-radius: 4px;
  }
  
.calc-popout.active > .calc-question, .calc-question:hover {
    border-bottom: 1px dashed #fc8eac;
    color: #fc8eac
}
.calc-popout.active > .calc-question + .calc-popout-info {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
}
.calc-answer-button {
    font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: normal;
	line-height: 19.5px;
	text-align: center;
    position: relative;
    top: 0px; 
	color: rgb(36, 28, 21);
	height: 43.5px;
	width: 150px;
	padding: 12px 24px;
	cursor: pointer;
	box-sizing: border-box;
    border: 1px solid black;
    border-radius: 26px; 
    margin-left: 10px;
}
.button-color-1 {
    background-color: #fc8eac;
    color: black;
}
.button-color-2 {
    background-color: #C98DFC;
    color: black;
}
.button-color-3 {
    background-color: #FCF58D;
    color: black;
}
.calc-answer-button:hover, .calc-answer-button-2:hover, .calc-price-button:hover {
    transition: top 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
    box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
    top: -3.05008px;
}
.calc-questions-ind {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}
.calc-answers-2 {
    display: flex;
    flex-direction: column;
}
.calc-answers-row {
    display: flex;
    justify-content: center;
}
.calc-answer-button-2 {
    font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: normal;
	line-height: 19.5px;
	text-align: center;
    position: relative;
    top: 0px; 
	color: rgb(36, 28, 21);
	height: 43.5px;
	width: 120px;
	cursor: pointer;
    border: 1px solid black;
    border-radius: 26px; 
    margin-left: 10px;
    margin-bottom: 10px;
}
.calc-answer-button-3 {
    font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: normal;
	line-height: 19.5px;
	text-align: center;
    position: relative;
    top: 0px; 
	color: rgb(36, 28, 21);
	height: 43.5px;
	width: fit-content;
	padding: 12px 24px;
	box-sizing: border-box;
    border: none;
    border-radius: 26px; 
    margin-left: 10px;
}
.calc-price {
    text-align: center;
}
.calc-price-button {
    font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: normal;
	line-height: 19.5px;
	text-align: center;
    position: relative;
    top: 0px; 
	background-color: #F60646;
	color: rgb(36, 28, 21);
	height: 43.5px;
	width: 138.305px;
	padding: 12px 24px;
	cursor: pointer;
	box-sizing: border-box;
    border: 1px solid black;
    border-radius: 26px; 
    margin-left: 10px;
}
.calc-price-num {
    width: 100px;
    padding: 8px;
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin: 10px auto;
}
.cost-calc-disclaimer {
    font-size: 15px;
    text-align: center;
}
