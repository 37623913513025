.colleges {
    text-align: center;
}
#colleges-header {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
}
.college-list {
    display: flex;
    justify-content: space-evenly;
}
.college-col p {
    margin-bottom: 25px;
}
.colleges-disclaimer {
    font-size: 15px;
    margin-top: 75px;
}
.college-unlisted {
    max-width: 460px;
    margin: 50px auto;
}
#college-unlisted-header {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
}
.colleges-email {
    color: blue;
    text-decoration: underline;
    font-size: 24px;
}