
.why-main-content {
    width: 100%;
    max-height: fit-content;
    min-height: 300px;
    display: flex;
    justify-content: space-around;
    padding: 40px;
}
.why-main-text-all {
    max-width: 640px;
    max-height: 260px;
    display: flex;
    flex-direction: column;
}
.why-main-text-top {
    padding-top: 20px;
    margin-top: 0;
    margin-bottom: 8px;
}
.why-main-text-title {
    margin-top: 8px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.why-main-text-bot {
    margin-top: 0px;
}
.why-button {
	font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    position: relative;
    top: 0px; 
    background-color: #fc8eac;
    color: rgb(36, 28, 21);
    height: 43.5px;
    width: 140px;
    text-align: center;
    padding: 12px 24px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 26px; 
    border: 1px solid black;
    text-decoration: none;
}
.why-button:hover {
    transition: top 0.1s ease-in-out, transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
    box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
    top: -5px;
}
.why-main-img {
    vertical-align: middle;
    height: 200px;
    top: 15%;
    position: relative;
}
.why-ind-circle {
    width: fit-content;
}
.why-circle-img-wrapper {
    max-height: 250px;
    max-width: 250px;
    border-radius: 50%;
    box-shadow: black 0px 0px 10px -2px;
    margin: 0 auto;
}
.why-circle-img-wrapper:hover {
    box-shadow: black 0px 0px 10px -1px;
}
.why-circle-img {
    width: 100%;
    min-height: 250px;
    height: 100%;
    vertical-align: middle;
    border-radius: 50%;
}
.why-circle-text {
    max-width: 300px;;
    text-align: center;
    font-size: 15px;
}
.why-circle-text p {
    margin: 15px;
}
.circle-text-head {
    font-weight: bold;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.why-circle-content {
    display: flex;
    justify-content: space-evenly;
    margin-top: 100px;
}