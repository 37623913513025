@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@900&display=swap');
.SideNav {
    height: 100%; 
    width: 280px;
    z-index: 1; 
    top: 0; 
    position: fixed; 
    background-color: white;  
    transition: .5s ease; 
    overflow-x: hidden; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-start;
    box-shadow: 0 0 5px 0 rgba(0,0,0, 0.4);
}
/* 
.SideNav #logodiv {
    width: 100%; 
    height: 40px; 
    border-bottom: 0.5px solid white; 
    text-align: center; 
}

.SideNav #logo {
    width: 80px; 
} */

.SideNav #header {
    font-size: 12px;;
    font-weight: black;
    color: black;
    margin-top: 57px; 
    margin-right: 113px; 
    max-width: 126px; 
    margin-left: 40px; 
   
}

.SideNav #linksdiv {
    display:flex; 
    flex-direction: column; 
}

.SideNav a {
    text-decoration: none; 
    font-size: 14px;
    font-weight: black;
    color: 	#787878; 
    margin-top: 20px; 
    width: 238px;
    height: 50px; 
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
}

.SideNav a span {
    margin-left: 40px;
}

.SideNav a:hover {
    color: white;
    background-color: #fc8eac;
} 

.SideNav img {
    width: 10px; 
    margin-right: 5px; 
    margin-bottom: 1px; 
}