@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

.maindashboard {  
    background-color: #252A41;
}

.userdashboard {
    position: fixed; 
    margin: 0;
    top: 0; 
    background-color: #252A41;
    width: 100%; 
    height: 100%; 
    display:flex; 
    flex-direction: column;  
    justify-content: center; 
}

.userdashboard #left-side {
    height: 450px;
    position: fixed; 
    left: 125px; 
    
}

.userdashboard #left-side h1{
    font-family: 'Inter';
    font-weight: 300; 
    color: white; 
}

.userdashboard #left-side h3{
    margin-top: 70px; 
    font-family: 'Inter';
    color: white; 
}

.userdashboard #left-side .left-side-p{
    color: #686A7B; 
}

.userdashboard #projectscontainer{
    display:flex; 
    flex-direction: column;
    /* justify-content:left;  */
}
#projectscontainer .noti-ind {
    max-width: 350px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #686A7B;
}
.noti-ind button {
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
}
.noti-ind button:hover, #projectscontainer .noti-ind:hover {
    color: white;
}
#right-side {
    position: fixed; 
    right: 10px; 
    height: 97%; 
    width: 50%;
    background-color: white; 
    border-radius: 25px; 
}


#right-side #essays{   
    height: 225px;
    width: 100%; 
    display: flex; 
    align-items: flex-start; 
    overflow-x: scroll;
}

#right-side #essays #upload {
    background-color: white; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    height: 172px;
	width: 140px;
    min-width: 140px;
    margin-left: 30px; 
    margin-top: 20px; 
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer; 

    border-radius: 2px;
    transition: transform 0.3s ease;
}

#upload:hover {
    transform: translateY(-8px);
    border: 1px solid #fc8eac;
}

#right-side #essays #upload img{
    width: 50px; 
    height: 50px; 
    margin-top: 30px; 
    margin-right: 5px; 
}

#right-side #essays #upload h2 {
    display: block; 
    font-family: 'Lato', sans-serif;
}

.right-side-header {
    font-family: 'Lato', sans-serif;
}
#app-checklist {
    max-width: 640px;
    margin: 15px auto;
    max-height: 350px;
    min-height: 350px;
    overflow-y: scroll;
}
#app-checklist li {
    margin-bottom: 1rem;
}
#app-checklist li span {
    font-weight: bold;
}
#app-checklist li ul {
    margin-top: 0.75rem;
}



/* CSS */
.button-55 {
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  position: fixed; 
  top: 350px; 
  right: 185px; 
}

.button-55:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.button-55:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}

#new-application-btn {
    width: max-content;
    background-color: #252A41;
    color: white;
    border: none;
    padding: 16px 24px;
    border-radius: 8px;
    height: 50px;
    margin: 80px 30px;

    white-space: nowrap;

    transition: all 0.3s ease;

    font-family:Verdana, Geneva, Tahoma, sans-serif;
}
#new-application-btn:hover {
    transform: translateY(-4px);
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    color: #fc8eac;
}

