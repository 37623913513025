.bigResetPassword {
    width: 100%; 
    height: 100%; 
    position: fixed;
    top: 0;
    left: 0;

    margin: 0; 
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
}


.resetPassword h1 {
    font-size: 20px; 
    margin-bottom: 50px; 
}

.resetPassword .fillloginform {
    margin-left: 70px; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-start;
    margin-top: 20px;
}

.resetPassword .fillloginform form {
    display: flex; 
    flex-direction: column; 
    align-items: flex-start;
}

.resetPassword .fillloginform form label{
    display: block; 
    margin-top: 10px; 
}

.resetPassword .fillloginform form input {
    font-family: 'Inter', "helvetica neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 100;
    background-color: white;
    width: 352px; 
    background: right -10px center #fff;
    background-size: 20px;
    border: 1px solid #c9cbd4;
    border-radius: 6px;
    color: #3f4450;
    padding: 9px 8px 7px;
    margin: 2px auto 20px auto;
    display: block;
    cursor: text;
    position: relative;
    height: 40px;
    box-sizing: border-box;
}


.resetPassword .fillloginform form img {
    width: 38px; 
    height: 38px; 
    /* border: 1.5px solid black;  */
    margin-left: 10px; 
    margin-top: 3px; 
    cursor: pointer;


}


.resetPassword .fillloginform form p {
   color: rgb(233, 76, 76); 
   font-weight: lighter;
   margin-left: 60px; 
}
/* Example of custom styles */
.custom-toast {
    width: 400px; 
    height: 300px;
    text-align: center; 
  }