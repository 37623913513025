
.inspiration {
    text-align: center;
}
#insp-header {
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
}
.insp-sec {
    position: relative;
    margin: 50px;
}
.insp-img-wrapper {
    max-width: 800px;
    border-radius: 20px;
    box-shadow: rgba(140, 140, 140, 0.75) 0px 0px 10px -2px;
}
#insp-img-wrapper2 {
    margin-left: auto;
}
.insp-photo {
    width: 100%;
    vertical-align: middle;;
    border-radius: 20px;
}
.insp-photo-text {
    max-width: 400px;
    background-color: #FEE6ED;
    padding: 1rem;
    border-radius: 10px;
    font-size: 16px;
    line-height: 29px;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(140, 140, 140, 0.75) 0px 0px 10px -2px;
}
.insp-photo-text-2 {
    max-width: 400px;
    background-color: #FEE6ED;
    padding: 1rem;
    border-radius: 10px;
    font-size: 16px;
    line-height: 29px;
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(140, 140, 140, 0.75) 0px 0px 10px -2px;
}
.insp-photo-text-body {
    text-align: left;
}
.insp-photo-text-body a:hover {
    color: #fc8eac;
}
.insp-photo-text-header {
    font-family: Georgia, 'Times New Roman', Times, serif;
}
