.review-header {
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.5px;
    margin-bottom: 50px;
}
.review-header-underline {
    border-bottom: 8px solid #fc8eac;
    padding-bottom: 3px;
}
table {
    table-layout: fixed;
    width: 80%;
    border-collapse: collapse;
    margin: 10px auto;
}
td {
    text-align: center;
    padding: 25px 10px;
}
td p {
    margin-bottom: 30px;
}
.test {
    border: 1px solid #BCBAB8;
}
.row-colored {
    background-color: #f6f6f4;
}
.policy-table-header {
    border-bottom: 8px solid #fc8eac;
    padding-bottom: 3px;
}

.policy-table-button {
    font-family: "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;
	font-size: 13px;
	font-weight: 500;
	line-height: 19.5px;
	text-decoration: none;
	text-align: center;
    position: relative;
    top: 0px; 
    background-color: #fc8eac;
	color: rgb(36, 28, 21);
	height: 43.5px;
	width: 138.305px;
	padding: 12px 24px;
	cursor: pointer;
	box-sizing: border-box;
	box-shadow: rgb(36, 28, 21) 0px 0.25008px 0px 0px;
    border-radius: 26px; 
    border: 1px solid black;
}
.policy-table-button:hover {
    transition: top 0.1s ease-in-out, transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7), -webkit-box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);
    box-shadow: rgb(36, 28, 21) 0px 0px 0px 1px, rgb(36, 28, 21) 0px 5.25008px 0px 0px;
    top: -5px;
}
.review-sec-section {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}
.review-sec-row {
    display: flex;
    justify-content: space-evenly;
}
.review-sec-ind {
    display: flex;
}
.review-sec-ind-img-wrapper {
    max-width: 200px;
    margin-top: 50px;
}
.review-sec-ind-img {
    width: 100%;
    vertical-align: middle;
}
.review-sec-ind-text {
    max-width: 260px;
    margin-left: 20px;
}
.review-sec-ind-text li {
    font-size: 14px;
}
.review-sec-ind-text-header {
    text-align: center;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 17px;
}