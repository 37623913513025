@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');


*, *::before, *::after {
    box-sizing: border-box; 
    
}




body {
    background-color: white; 
    margin: 0; 
}

.container {
  width: 625px;
  /* display: inline !important; */
}

.container .ql-editor { 
    font-family: 'Inter', sans-serif; 
    font-size: 17px;
    color: rgb(14, 16, 26);
    line-height: 28px; 
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    width: 625px; 
    min-height:10px; 
    -webkit-font-smoothing: antialiased;
    margin-left: 70px;  
    margin-top:100px; 
    /* box-shadow: 0 0 5px 0 rgba(0,0,0, 0.5);  */
    background-color: white; 
}






.ql-container.ql-snow {
    border: none; 
}

.container div.ql-toolbar.ql-snow {
    margin-top: 2px; 
    border: none; 
    background-color: rgb(255, 255, 254);
    position: fixed; 
    bottom: 0px; 
    margin-left:80px; 
    z-index: 1; 
    width:100%;
    padding-bottom: 10px;
    /* box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5); */
}



.ql-editor[data-placeholder]::before {
    margin-left:70px; 
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 17px;  
    color: #6D758D; 
    font-style: normal; 
}

.word-counter {
    font-family: "Inter", sans-serif; 
    background-color: transparent;
    font-weight: 400; 
    outline: rgb(109, 117, 141) solid 0.5px;  
    color: rgb(109, 117, 141);
    font-size: 13px; 
    padding: 5px 10px;  
    /* margin-left: 600px;  */
    position: fixed; 
    bottom: 10px; 
    left: 500px; 
    z-index: 2;
}


h2 #commentstitle{
    font-family: "Inter", sans-serif; 
    color: black; 
    font-size: 18px; 
    /* margin-left:750px;  */
    position: fixed; 
    top: 20px; 
}



#mycartoon {
    margin-left: 800px;
    position: fixed; 
    top: 65%; 
    width:400px; 
}

#clouds {
    margin-left:850px; 
    position:fixed;
    top: 0%; 
    
    width: 300px; 
}


#matchingmessage {
    width: 400px; 
    position: fixed; 
    top: 55%; 
    font-weight: 600; 
    margin-left: 825px; 
}

#sidenav {
    height: 100%; 
    width: 190px;
    position: fixed; 
    z-index: 1; 
    top: 0; 
    right: 0; 
    background-color:white; 
    transition: .5s ease; 
    overflow-x: hidden; 
    padding-top: 20px; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    box-shadow: 0 0 5px 0 rgba(0,0,0, 0.4);
}



/* CSS */
.button-28 {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  
  will-change: transform;
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}



/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}


/* CSS */
.button-56 {
  align-items: center;
  background-color: #fee6e3;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-56:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.button-56:hover:after {
  transform: translate(0, 0);
}

.button-56:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}
.commentscontainer {
    perspective: 800px; 
    position: fixed;
    margin-top:80px;
    margin-left: 720px;
    max-height:600px;
    width:480px;
    overflow-x:scroll;
}

.comment-box {
    background-color:white; 
    box-shadow: 0 0 5px 0 rgba(0,0,0, 0.5);
    max-width:437px;
    width:450px;
    margin-bottom: 8px; 
    border-radius: 16px; 
    margin-top: 20px;
    margin-left: 30px;
    display:flex; 
    flex-direction: column; 
    align-items: center; 
    overflow: hidden;
    transition: transform 0.3s ease 0s;
}



.mycomments {
    display:block; 
    background-color: white;
    border: none; 
    height: 150px; 
    width:364px; 
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px; 
    line-height: 25px;  
    color: black; 
    outline: none; 
    word-wrap: break-word; 
    resize: none; 
}


.select_tag {
    background-color: white;
    margin-top: 8px; 
    margin-bottom:8px; 
    margin-right: 228px;  
    border: none; 
    outline: none; 
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;  
    color: #6D758D; 
    height:20px !important; 
}

.checkmark_comment {
    width:12px; 
    margin-right:10px; 
}

.pinkstar_comment {
    width: 30px; 
}

/* 
.ql-editor .highlighted{
  border-bottom: 2px solid #EA1537;  
}  */


.ql-my-custom-format {
  background-color: black;
  /* Add any other desired styles */
}


.testeditingtool {
  display:flex;
  /* align-items: flex-start */
  /* justify-content: start; */
}

.documentName {
  display: inline; 
  
 
    /* Font & Text */
    font-family: "Inter", "Helvetica Neue", sans-serif, Arial;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: start;
    vertical-align: top;
    word-spacing: 0px; 
    color: rgb(14, 16, 26);
    height: 32px;
    width: 136px;
    padding: 4px 0px;
    min-height: 0px;
    max-width: calc(100% - 8px);
    cursor: pointer;
    position: fixed; 
    top: 15px; 
    left: 85px; 
    
}



/* CSS */
.button-1 {
  background-color: #EA4C89;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-1:hover,
.button-1:focus {
  background-color: #F082AC;
}

.bubbly-button {
  font-family: "Helvetica", "Arial", sans-serif;
  display: inline-block;
  font-size: 0.9em;
  padding: 1em 2em;
  margin-top: 100px;
  margin-bottom: 60px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ff0081;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);
}
.bubbly-button:focus {
  outline: 0;
}
.bubbly-button:before, .bubbly-button:after {
  position: absolute;
  content: "";
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}
.bubbly-button:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, transparent 20%, #ff0081 20%, transparent 30%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, transparent 10%, #ff0081 15%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}
.bubbly-button:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, transparent 10%, #ff0081 15%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%), radial-gradient(circle, #ff0081 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}
.bubbly-button:active {
  transform: scale(0.9);
  background-color: #e60074;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}
.bubbly-button.animate:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}
.bubbly-button.animate:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

div .testeditingtool #sidenav .editing_tool_buttons {
	font-family: "Inter", "Helvetica Neue", sans-serif, Arial;
  display:flex; 
  align-items: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 24px;
	text-align: start;
	background-color: rgba(0, 0, 0, 0);
  color: rgb(14, 16, 26);
	height: 60px;
	width: 160px;
	border: 1px solid rgba(198, 203, 222, 0.7);
	margin: 0px 16px;
  margin-top: 30px; 
	padding: 12px;
	transition: background-color 0.2s ease 0s;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

div .testeditingtool #sidenav .editing_tool_buttons:hover {
  background-color: #fff0f3;
}



.teacher-typing-container {
  position: relative;
}

.computer-container {
  position: relative;
  width: 600px;
  height: 400px;
  background-color: #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: #fff;
}

.key-row {
  display: flex;
  gap: 5px;
}

.key {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
}

.monitor {
  position: absolute;
  top: 70px;
  left: 100px;
  width: 600px;
  height: 400px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.screen {
  padding: 20px;
  height: 100%;
  overflow: hidden;
}

.teacher-typing {
  position: absolute;
  top: 40px;
  left: 40px;
}

.hands {
  position: absolute;
  width: 80px;
  height: 50px;
  background-color: #f0f0f0;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: typing-animation 2s infinite alternate;
}

@keyframes typing-animation {
  0% {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
}
