.payment-options {
  position: relative;
  margin-bottom: 50px;
}
.payment-options a {
  text-decoration: none;
  color: black;
}
.pay-options-all-boxes {
  display: flex;
}
.payment-options h2 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 30px;
font-weight: 400;
letter-spacing: -1px;
line-height: 48px;
  text-align: center;
}
.pay-options-box {
  max-width: 320px;
  cursor: pointer;
}
.individual-box {
  width: 320px;
  border: .1px solid #BCBAB8;
  text-align: center;
}
.individual-box:hover {
  box-shadow: rgba(140, 140, 140, 0.75) 0px 0px 10px -2px;
  z-index: 2;
}
.pay-options-header {
  font-family: Georgia, 'Times New Roman', Times, serif;
}
.pay-options-features {
  line-height: 50px;
}
.pay-options-features-line {
  padding: 15px;
  border-bottom: 1px solid #DBD9D2;
}
.pay-options-features-line-x {
  color: #CDCAC1;
}