.footer {
    position: absolute;
    bottom:auto;
    width: 100%;
    height: 200px;
    margin-top: 100px;
    background-color: #FECEDB;
    display: flex;
    justify-content: space-evenly;
}
.footer-col-box {
    display: flex;
    justify-content: center;
}
.footer-col {
    display: flex;
    flex-direction: column;
    margin: 10px 30px;
    text-align: center;
}
.footer img {
    height: 100px;
    margin-top: 50px;
}
.footer-col a, .footer-logo-links {
    color: black;
    border-radius: 4px;
    padding: 4px;
}
.footer-col a:hover, .footer-logo-links:hover {
    background-color: #FFF7F9;
}
.footer-logo-links {
    text-decoration: none;
    font-size: 12px;
}
.footer-logo-box p {
    width: max-content;
    margin: 15px auto;
}
